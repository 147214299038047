<template>
    <OModal ref="modal" @shown="updateModalFields" @hidden="clearAllFields">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Create new order') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>{{ $t("Legal Entity") }}:</label>
                            <input class="form-control" v-model="legalEntityFromContext.name" disabled/>
                        </div>
                        <div class="col-6 form-group">
                            <div class="d-flex flex-row justify-content-between align-items-baseline">
                                <label>{{ $t("Customer") }}:</label>
                                <a style="font-size: 0.75rem;" href="/add-new-customer" target="_blank">+ {{ $t("Add new Customer") }}</a>
                            </div>
                            <ODataLookup class="form-control" :dataObject="dsCustomersLkp" disableRecent v-model="customer.name" :bind="sel=>{customer.id = sel.ID; customer.name = sel.Name;}">
                                <OColumn field="CustomerNo" width="100" :headerName="$t('Customer No.')"/>
                                <OColumn field="Name" width="350" :headerName="$t('Name')"/>
                                <OColumn field="Currency" width="100" :headerName="$t('Currency')"/>
                            </ODataLookup>  
                        </div>
                        <div class="col-6 form-group">
                            <label>{{ $t("Currency") }}:</label>
                            <ODataLookup class="form-control" :dataObject="dsCurrenciesLkp" disableRecent v-model="currency" :bind="sel=>{currency = sel.Currency}">
                                <OColumn field="Currency" width="200" :headerName="$t('Currency')"/>
                            </ODataLookup>  
                        </div>
                        <div class="col-6 form-group">
                            <label>{{ $t("OrderType") }}:</label>
                            <ODataLookup class="form-control" :dataObject="dsOrderTypeLkp" disableRecent v-model="orderType.name" :bind="sel=>{orderType.id = sel.ID; orderType.name = sel.Name;}">
                                <OColumn field="Name" width="120" :headerName="$t('Type')"/>
                                <OColumn field="Default" width="90" :headerName="$t('Default')"/>
                                <OColumn field="InternalOrder" width="115" :headerName="$t('Internal Order')"/>
                                <OColumn field="NotInvoiceableOrder" width="160" :headerName="$t('Not Invoiceable Order')"/>
                            </ODataLookup>  
                        </div>
                    </div>
                    <span class="text-danger float-end" v-if="!dsLegalEntities.current.LegalEntity" :title="$t('Click to set Context')" style="cursor: pointer;" @click="openLookup">
                        {{ $t("Context must be set to a valid Legal Entity") }}
                        <div ref="lookupWrapper" class="lookup-wrapper">
                            <ODataLookup ref="LegalEntLkp" :style="lookupStyles" class="form-control-plaintext" :dataObject="dsLegalEntitiesLkp" disableRecent noClear :bind="sel=>{updateContext(sel)}" data-columns="LegalEntity:200px">
                                <OColumn field="LegalEntity" width="250" :headerName="$t('Legal Entity')"/>
                            </ODataLookup>
                        </div>
                    </span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" :disabled="!(dsLegalEntities.current.LegalEntity&&dsLegalEntities.current.ID&&customer.id&&currency&&orderType.id) || isLoading" @click="createNewOrder">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm" style="0.35rem" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                        {{ $t("Submit") }}
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, defineExpose, onMounted } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import { contextSelector } from 'o365.controls.NavBar.js';
    import context from 'o365.modules.Context.ts';
    
    // DATASOURCES
    const dsLegalEntities = getOrCreateDataObject({
        id: 'dsLegalEntities',
        viewName: 'aviw_Accounts_LegalEntities',
        uniqueTable: null,
        selectFirstRowOnLoad: true,
        distinctRows: false,
        maxRecords: -1,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ID", type: "Number" },
            { name: "Currency", type: "string" },
            { name: "CountryCode", type: "string" },
            { name: "LegalEntity", type: "string" }
        ]
    });
    const dsLegalEntitiesLkp = getOrCreateDataObject({
        id: 'dsLegalEntitiesLkp' + crypto.randomUUID(),
        viewName: 'aviw_Accounts_LegalEntities',
        uniqueTable: null,
        distinctRows: false,
        maxRecords: -1,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "ID", type: "Number" },
            { name: "LegalEntity", type: "string" }
        ]
    });
    const dsCustomersLkp = getOrCreateDataObject({
        id: 'dsCustomersLkp' + crypto.randomUUID(),
        viewName: 'aviw_Accounts_Customers',
        uniqueTable: null,
        distinctRows: false,
        maxRecords: -1,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "ID", type: "Number" },
            { name: "Name", type: "string"},
            { name: "Currency", type: "string" },
            { name: "CustomerNo", type: "Number" },
            { name: "LegalEntity_ID", type: "Number" }
        ],
        whereClause: 'Closed IS NULL'
    });
    const dsCurrenciesLkp = getOrCreateDataObject({
        id: 'dsCurrenciesLkp' + crypto.randomUUID(),
        viewName: 'atbv_Accounts_Currencies',
        uniqueTable: null,
        distinctRows: false,
        maxRecords: 25,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "ID", type: "Number" },
            { name: "Currency", type: "string" },
            { name: "CountryCode", type: "string"}
        ],
        masterDataObject_ID: "dsLegalEntities",
        masterDetailDefinition:[
            {
                "detailField": "CountryCode",
                "operator": "equals",
                "masterField": "CountryCode" 
            }
        ],
        disableAutoLoad: false,
    });
    const dsDefaultOrderType = getOrCreateDataObject({
        id: 'dsDefaultOrderType' + crypto.randomUUID(),
        viewName: 'aviw_Accounts_LegalEntitiesDefaultOrderType',
        uniqueTable: null,
        distinctRows: false,
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "ID", type: "Number" },
            { name: "OrderType", type: "string" },
            { name: "OrderType_ID", type: "Number" }
        ]
    });   
    const dsOrderTypeLkp = getOrCreateDataObject({
        id: 'dsOrderTypeLkp' + crypto.randomUUID(),
        viewName: 'atbv_Accounts_OrderTypes',
        uniqueTable: null,
        distinctRows: false,
        maxRecords: 25,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "ID", type: "Number" },
            { name: "Name", type: "string", sortOrder: 2, sortDirection: "desc"},
            { name: "Default", type: "boolean", sortOrder: 1, sortDirection: "desc" },
            { name: "InternalOrder", type: "boolean" },
            { name: "NotInvoiceableOrder", type: "boolean" },
        ],
    });

    dsLegalEntities.enableContextFilter((path, id)=>{
        return `ID = ${id}`;
    });
    dsDefaultOrderType.enableContextFilter((path, id)=>{
        return `ID = ${id}`;
    });
    dsOrderTypeLkp.enableContextFilter((path, id)=>{
        return `LegalEntity_ID = ${id}`;
    });
    dsCustomersLkp.enableContextFilter((path, id)=>{
        return `LegalEntity_ID = ${id}`;
    }); 
    // DATASOURCES END
    
    const procCreateOrder = getOrCreateProcedure(
        { 
            id:"procCreateOrder" + crypto.randomUUID(), 
            procedureName: "astp_Accounts_CreateOrder"
        }
    );
    const props = defineProps({
        subseaAssignmentId: {
            type:Number,
            default: null
        },
        employmentContractId: {
            type:Number,
            default:null
        }
    });
    const emit = defineEmits(["OrderCreated"]);

    const lookupStyles = ref({ visibility:"hidden", height: "1px" });
    const modal = ref();
    const isLoading = ref(false);
    const LegalEntLkp = ref();
    const legalEntityFromContext = ref({ id:null, name:null });
    const customer = ref({ id:null, name:null });
    const orderType = ref({ id:null, name:null });
    const currency = ref(null);

    const openModal = () => {
        modal.value.show();
    };
    const hideModal = () =>{
        modal.value.hide();
    }

    defineExpose({ openModal, hideModal });

    onMounted(()=>{
        dsLegalEntities.load();
        dsDefaultOrderType.load();
    });

    function createNewOrder(){
        isLoading.value = true;
        procCreateOrder.execute({
            LegalEntity_ID: dsLegalEntities.current.ID,
            Customer_ID: customer.value.id,
            Currency: currency.value,
            OrderType_ID: orderType.value.id,
            EmploymentContract_ID: props.employmentContractId,
            SubSeaAssignment_ID: props.subseaAssignmentId,
        }).then((pData)=>{
            emit("OrderCreated", pData.Table[0].ID);
            isLoading.value = false;
        }).catch(()=>{
            isLoading.value = false;
        });
    }

    function updateModalFields(){
        legalEntityFromContext.value.id = context.id;
        legalEntityFromContext.value.name = context.orgUnit;
        currency.value = dsLegalEntities.current.Currency;
        orderType.value.id = dsDefaultOrderType.current.OrderType_ID;
        orderType.value.name = dsDefaultOrderType.current.OrderType;
    }

    function openLookup(){
        LegalEntLkp.value.open();
    }

    function clearAllFields(){
        legalEntityFromContext.value.id = null;
        legalEntityFromContext.value.name = null;
        currency.value = null;
        orderType.value.id = null;
        orderType.value.name = null;
        customer.value.id = null;
        customer.value.name = null;
    }

    function updateContext(record){
        contextSelector.updateContext(record.ID)
        legalEntityFromContext.value.id = record.ID;
        legalEntityFromContext.value.name = record.LegalEntity;
        dsLegalEntities.recordSource.whereClause = `ID = ${record.ID}`;
        dsLegalEntities.load();
    }
</script>

<style scoped>
    .lookup-wrapper .form-control-plaintext {
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: inherit;
    }
</style>
